<template>
	<div id="product">
		<lazy-hydrate when-idle>
			<div class="top" v-if="product.video">
				<Video
					id="product-video"
					:params="{ background: 1, allowfullscreen: 0, autoplay: 1 }"
					:src="product.video"
				/>
			</div>
		</lazy-hydrate>
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<lazy-hydrate when-idle>
						<breadcrumbs class="desktop-only" :breadcrumbs="breadcrumbs"/>
					</lazy-hydrate>
				</div>
			</div>
			<div class="row pb-6">
				<div class="col-12">
					<lazy-hydrate>
						<h2>{{ productName }}</h2>
					</lazy-hydrate>
				</div>
				<div class="col-12">
                    <nuxt-img v-if="product.top_image" :src="product.top_image" quality="100" :alt="productName"/>
				</div>
                <div class="col-lg-12 product-overview mt-4">
                    <div class="flex">
                        <h3 class="mr-5">{{ $t('Overview') }}</h3>
                        <lazy-hydrate>
                            <sf-loader :loading="productDataIsLoading">
                                <HTMLContent v-if="productDescription" :content="productDescription" tag="p">
                                    {{ productDescription }}
                                </HTMLContent>
                            </sf-loader>
                        </lazy-hydrate>
                    </div>
                    <h3
                        v-if="productLabel"
                        class="product-new-label"
                    >
                        {{ productLabel }}
                    </h3>
                </div>
			</div>
			<Documents :productSku="sku" :hasOneImage="false"/>
                <div class="hero" v-if="sideImage">
                    <img
                        v-if="showSideImage"
                        :src="sideImage.url"
                        :alt="getProductName(product)"
                    >
                    <img
                        v-else
                        :src="topImage.url"
                        :alt="getProductName(product)"
                    >
                </div>
                <div class="flex-center side-image" v-if="sideImage && topImage">
                    <div class="left pb-2" :class="{ 'active-border': showSideImage }" @click="showSideImage=true">{{ $t('Side') }}</div>
                    <div class="right pb-2" :class="{ 'active-border': !showSideImage }" @click="showSideImage=false">{{ $t('Top') }}</div>
                </div>
		</div>
		<div class="container">
			<div class="related-products-slider">
				<client-only>
					<lazy-hydrate when-visible>
						<ProductsCarousel
							:products="product.related_products"
							:loading="productDataIsLoading"
							:title="$t('Compatible with')"
						/>
					</lazy-hydrate>
				</client-only>
			</div>
		</div>
	</div>
</template>
<script>
import {
	computed,
	defineComponent,
	onMounted,
	onUnmounted,
	ref,
	useAsync,
	useRoute
} from '@nuxtjs/composition-api';
import Breadcrumbs from '@/components/Breadcrumbs';
import Video from '@/components/Common/Video';
import productGetters, { getName as getProductName } from '@/getters/productGetters';
import { CacheTagPrefix, useCache } from '@vue-storefront/cache';
import cacheControl from '~/helpers/cacheControl';
import { SfLoader } from '@storefront-ui/vue';
import { useCustomerStore } from '~/stores/customer';
import { useProduct, useLabels, useUiState } from '~/composables';
import { findActiveCategory } from '~/modules/catalog/category/helpers';
import isEmpty from 'lodash.isempty';
import ProductsCarousel from '~/components/ProductsCarousel';
import Documents from '@/components/Product/Documents';
import HTMLContent from '@/components/HTMLContent';
import ProductGallery from '@/components/Product/ProductGallery';
import useCategory from '~/modules/catalog/category/components/category/useCategory';

export default defineComponent({
	name: 'MinimalProduct',
	components: {
		ProductGallery,
		HTMLContent,
		Breadcrumbs,
		Video,
		SfLoader,
		Documents,
		ProductsCarousel,
	},
    data() {
        return {
            showMapPopup: false,
        };
    },
	props: {
		loading: Boolean,
		products: Object,
		sku: String,
	},
    methods: {
        openMapPopup() {
            this.showMapPopup = true;
        },
    },
	middleware: cacheControl({
		'max-age': 36000,
		'stale-when-revalidate': 5,
		'reuse': true,
	}),
	head() {
		if (!this.product) {
			return null;
		}

		const title = this.product?.name ? this.product.name + ' - Vizulo' : 'Vizulo';
		const meta = [];
		let script = [];


		if (this.product.image_360) {
			script = [
				{
					src: 'https://scripts.sirv.com/sirvjs/v3/sirv.js',
					type: 'text/javascript',
					async: true,
					'data-components': 'spin, zoom, video'
				}
			];
		}

		return {
			title,
			meta,
			script
		};
	},
	setup(props) {
		const { user } = useCustomerStore();
		const route = useRoute();
		const { path, query } = route.value;
		const { isMobile } = useUiState();
		const { addTags } = useCache();
		const { loading: productsLoading } = useProduct(props.sku);
		const { categoryTree, lastVisitedCategoryUrlPath } = useCategory();
		const productLabelsQuery = ref({ items: [], pageInfo: {}, totalCount: null });
		const { loadingLabels, getLabels } = useLabels();
		const windowWidth = ref(process.browser ? window.innerWidth : '');
		const onWidthChange = () => windowWidth.value = window.innerWidth;
		const displayWidth = computed(() => windowWidth.value);
        const showSideImage = ref(true);

		const product = computed(() => {
			return Array.isArray(props.products?.items) && props.products?.items[0] ? props.products?.items[0] : {};
		});

		const productDataIsLoading = computed(() => {
			return props.loading || (productsLoading.value && !productGetters.getName(product.value))
		});

		const productName = computed(() =>
			getProductName(product.value)
		);

		const productShortDescription = computed(() => product.value.short_description?.html || '');
		const productDescription = computed(() => product.value.description?.html || '');
		const productNotes = computed(() => product.value.notes);

		const productCategories = computed(() => {
			if (lastVisitedCategoryUrlPath.value) {
				return [findActiveCategory(categoryTree.value, lastVisitedCategoryUrlPath.value)];
			}

			return productGetters.getCategoriesBasedOnUrlPath(product.value, path);
		});

		const breadcrumbs = computed(() => {
			return productGetters.getBreadcrumbs(
				product.value,
				Array.isArray(productCategories.value) ? productCategories?.value[productCategories?.value?.length - 1] : [],
			);
		});

        const productGallery = computed(() => {
            const gallery = productGetters.getGallery(product.value).map((img) => ({
                mobile: { url: img.small },
                desktop: { url: img.normal },
                big: { url: img.big },
                sirv: img.sirv,
                // eslint-disable-next-line no-underscore-dangle
                alt: product.value._name || product.value.name,
                tags: img.tags,
                uid: Math.ceil(Math.random() * 0x10000)
            }));

            return gallery;
        });

        const sideImage = computed(() => {
            const sideImg = productGallery.value.find((img) => {
                return img.desktop.url.includes('side');
            });

            return sideImg ? { url: sideImg.desktop.url, alt: sideImg.alt } : null;
        });

        const topImage = computed(() => {
            const topImg  = productGallery.value.find((img) => {
                return img.desktop?.url.includes('top');
            });

            return topImg  ? { url: topImg.desktop?.url, alt: topImg.alt } : null;
        });

		const categoryIds = computed(() => productGetters.getCategoryIds(product.value));
		const productLabel = computed(() => productGetters.getProductLabel(product.value));

		const labels = computed(() => {
			return productLabelsQuery.value?.items || []
		});

		const abbreviation = computed(() =>
			!isEmpty(product.value?.abbreviation) ? product.value.abbreviation : null);

		useAsync(async () => {
			const labelsSearchQuery = {
				filter: {
					product_sku: {
						eq: props.sku
					}
				}
			}

			productLabelsQuery.value = props.sku !== '' ? await getLabels({ ...labelsSearchQuery }) : null;

			const tags = [
				{
					prefix: CacheTagPrefix.View,
					value: `product-${product.value.uid}`,
				},
			];

			const productTags = {
				prefix: CacheTagPrefix.Product,
				value: product.value.uid,
			};

			const categoriesTags = categoryIds.value.map((catId) => ({
				prefix: CacheTagPrefix.Category,
				value: catId,
			}));

			addTags(tags.concat(productTags, categoriesTags));
		});

		onMounted(() => {
            window.addEventListener('resize', onWidthChange);
        });

		onUnmounted(() => window.removeEventListener('resize', onWidthChange));

		const galleryTemplate = ref(null);

		return {
			breadcrumbs,
			displayWidth,
			product,
			abbreviation,
			productDataIsLoading,
			productDescription,
			productGallery,
			getProductName,
			productShortDescription,
			productNotes,
			labels,
			loadingLabels,
			...productGetters,
			user,
			productName,
			productsLoading,
			isMobile,
			galleryTemplate,
			productLabel,
            query,
            showSideImage,
            sideImage,
            topImage
		};
	}
});
</script>
<style lang="scss" scoped>
#product {
	.product-video {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
		max-width: 100%;

		embed, iframe, object {
			position: absolute;
			top: 0;
			left: 120px;
			width: 100%;
			height: 100%;
			border: none;
		}
	}

    img {
        width: 100%;
        background: var(--c-light-gray);
    }

	.sections {
		position: sticky;
		top: 70px;
		z-index: 10;
		overflow-x: scroll;
		border-bottom: 1px solid var(--_c-gray-secondary);

		@media (min-width: 576px) {
			overflow-x: unset;
		}

		@media (min-width: 768px) {
			border-bottom: unset;
		}

		@media (min-width: 992px) {
			top: 0;
		}

		&__menu {
			@media (min-width: 768px) {
				border-bottom: 1px solid var(--_c-gray-secondary);
			}
		}

		&__section {
			margin-right: 23px;

			@media (max-width: 992px) {
				font-size: 14px;
			}
		}
	}

	.product {
		&-gallery {
			order: -1;

			@media (min-width: 992px) {
				order: unset;
			}

			::v-deep .sf-loader__overlay {
				top: 50%;
				transform: translateY(-50%);
			}
		}

        &-new-label {
            margin: 0;
            text-align: center;
            color: var(--c-white);
            background: var(--c-dark);
            padding: 16px;
        }
	}

    .product-overview {
        h3 {
            font-weight: normal !important;
            text-transform: uppercase;
        }

        ::v-deep p {
            font-weight: bold;
        }
    }

	.min-height-large {
		min-height: none;

		@media (min-width: 992px) {
			min-height: 760px;
		}
	}
}

.side-image {
    cursor: pointer;
    margin-bottom: 2rem;
    margin-top: 1rem;

    .left, .right {
        text-align: center;
        border-bottom: 2px solid rgba(227, 227, 227, 0.78);
        font-weight: bold;
        font-size: 18px;
        width: 5rem;
    }
}

.active-border {
    border-bottom: 2px solid black !important; /* Add a black bottom border */
}

.related-products-slider {
	margin-bottom: 35px;

	::v-deep .project {

		@media (min-width: 992px) {
			margin-bottom: 50px;
		}
	}

	::v-deep h5 {
		font-size: 20px;
		line-height: 28px;
	}
}

::v-deep .svg-image {
	float: right;
}

::v-deep .sf-loader {
	&__overlay {
		position: relative;
	}
}
</style>