import { render, staticRenderFns } from "./MinimalProduct.vue?vue&type=template&id=e5608b12&scoped=true&"
import script from "./MinimalProduct.vue?vue&type=script&lang=js&"
export * from "./MinimalProduct.vue?vue&type=script&lang=js&"
import style0 from "./MinimalProduct.vue?vue&type=style&index=0&id=e5608b12&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5608b12",
  null
  
)

export default component.exports